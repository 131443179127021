import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  AiOutlineTwitter,
  AiFillInstagram,
  AiOutlineInfoCircle,
} from "react-icons/ai";

const Footer = () => {
  return (
    <Container fluid className="footer">
      <Row>
        <Col md="4" className="footer-copywright">
          <p>© 2023 Lia Love</p>
        </Col>
        <Col md="4" className="footer-body">
          <ul className="footer-icons">
            <li className="social-icons">
              <a
                href="https://x.com/lialove1988"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiOutlineTwitter />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.instagram.com/lialoveoffi"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillInstagram />
              </a>
            </li>
            <li className="social-icons">
              <Link to="/impressum" style={{ color: "white" }} className="">
                <AiOutlineInfoCircle />
              </Link>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
